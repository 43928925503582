import { createMuiTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
	spacing: 5,
	palette: {
		type: "dark",
		background: { paper: "#18302A", default: "#18302A" },
		primary: { main: "#38704B" },
		alternative: { main: "#18302A" },
		secondary: { main: "#77BD8F" },
		greenLight: { main: "#77BD8F" },
		greenDark: { main: "#18302A", dark: "#0C1B17" },
		greenAlt: { main: "#142520" },
		yellow: { main: "#ffea8d", dark: "#ECD673" },
	},
	typography: {
		fontFamily: [
			"Nunito",
			"Open Sans",
			"Helvetica Neue",
			"Arial",
			"sans-serif",
		].join(","),
		h1: {
			fontSize: "40px",
			letterSpacing: "-3px",
			marginBottom: "10px",
			fontWeight: "700",
			[breakpoints.up("md")]: {
				fontSize: "50px",
			},
		},
		h2: {
			fontSize: "38px",
			letterSpacing: "-2px",
			marginBottom: "10px",
		},
		h3: {
			fontSize: "22px",
			letterSpacing: "-1.5px",
			marginBottom: "10px",
			[breakpoints.up("md")]: {
				fontSize: "30px",
			},
		},
		h4: {
			fontSize: "22px",
			letterSpacing: "-1px",
			fontWeight: 600,
			fontFamily: "Open Sans",
		},
		body1: {
			fontFamily: "Open Sans",
			fontSize: "15px",

			[breakpoints.up("md")]: {
				fontSize: "16px",
			},
		},
		body2: {
			fontFamily: "Open Sans",
			fontSize: "17px",
			lineHeight: "1.8",
		},
		caption: {
			fontFamily: "Open Sans",
			color: "#DCEEE1",
		},
	},
	stepper: {
		iconColor: "#269E51",
		hoveredIconColor: "#269E51",
		inactiveIconColor: "#269E51",
	},
	overrides: {
		MuiList: {
			root: {
				background: "#161915",
			},
		},
		MuiOutlinedInput: {
			root: {
				background: "rgba(255,255,255,0.15)",
			},
		},
		MuiStep: {
			root: {
				padding: 0,
			},
			horizontal: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
		MuiStepLabel: {
			iconContainer: {
				padding: 0,
			},
		},
		MuiButton: {
			// Name of the styleSheet
			root: {
				// Name of the rule
				textTransform: "inherit",
				borderRadius: 3,
				border: 0,
				color: "white",
				height: 40,
				padding: "0 30px",
				fontWeight: "500",
				fontFamily: "Open Sans",
				boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .30)",
			},
			text: {
				boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, .30)",
				fontSize: 17,
				padding: "0 ",
				textTransform: "inherit",
				fontFamily: "Nunito",
			},
		},
	},
	MuiTypography: {
		variantMapping: {
			h1: "h1",
			h2: "h2",
			h3: "h3",
			h4: "h4",
			h5: "h5",
			h6: "h6",
			subtitle1: "h2",
			subtitle2: "h2",
			body1: "p",
			body2: "span",
		},
	},
})

export default theme
