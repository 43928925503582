import App from "./App"
import { BrowserRouter } from "react-router-dom"
import React, { Suspense } from "react"
import { hydrate } from "react-dom"
import theme from "./utils/theme"
import { ThemeProvider } from "@material-ui/core/styles"
import { useSSR } from "react-i18next"
import "./utils/i18n"
import { CssBaseline } from "@material-ui/core/"
import { loadableReady } from "@loadable/component"
import LoadingHome from "./components/LoadingHome"
import { HelmetProvider } from "react-helmet-async"

const BaseApp = () => {
	useSSR(window.initialI18nStore, window.initialLanguage)
	return (
		<Suspense fallback={<LoadingHome />}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<CssBaseline />
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</BrowserRouter>
			</ThemeProvider>
		</Suspense>
	)
}
loadableReady(() => {
	hydrate(<BaseApp />, document.getElementById("root"), () => {
		const jssStyles = document.getElementById("jss-ssr")
		if (jssStyles && jssStyles.parentNode)
			jssStyles.parentNode.removeChild(jssStyles)
	})
})
if (module.hot) {
	module.hot.accept()
}
