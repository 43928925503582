import { useState, useEffect } from "react"
import { sendEvent } from "./Analytics"

export function calculateElectricityEmission(
	value,
	country,
	household,
	green,
	average
) {
	const electricityGrid = require("../../public/data/electricity-grid.json") //with path
	let filteredArray = electricityGrid.filter(function (item) {
		return item.country === country
	})
	if (filteredArray.length === 0) {
		filteredArray = [
			{
				country: "Average",
				kgCO2PerKWh: 0.0324402,
				units: "",
			},
		]
	}
	const greenReduction = {
		green0: 1,
		green25: 0.75,
		green50: 0.5,
		green75: 0.25,
		green100: 0,
	}
	console.log(value, household)
	sendEvent("Electricity", "Completed Calculation")
	console.log(
		value,
		filteredArray,
		filteredArray[0].kgCO2PerKWh,
		average,
		greenReduction[green]
	)
	if (average === 1) {
		const emission =
			((value * filteredArray[0].kgCO2PerKWh) / average) * greenReduction[green]
		return emission
	} else {
		const emission =
			((value * filteredArray[0].kgCO2PerKWh) / Number(household)) *
			greenReduction[green]
		return emission
	}
}
// export function calculateElectricityGreen(value, green) {
//   const greenReduction = {
//     green0: 0,
//     green25: 0.75,
//     green50: 0.5,
//     green75: 0.25,
//     green100: 1,
//   };
//   const emission = (value * greenReduction[green])
//   console.log(emission);
//   return emission;
// }
export function getCountryFootprint(country) {
	const footprintsCountry = require("../../public/data/average-footprint-country.json") //with path
	if (
		country !== "" &&
		footprintsCountry[country] !== undefined &&
		footprintsCountry[country].kg !== 0
	) {
		var footprint = {
			name: country,
			carbonfootprint: footprintsCountry[country].kg,
		}
		return footprint
	} else {
		var footprintWorld = {
			name: "Worldwide average",
			carbonfootprint: 4800,
		}
		return footprintWorld
	}
}
export function calculateCarEmission(carSize, fuelType, distance) {
	var week = 52
	var emissionFactors = {
		small: {
			diesel: 0.14024,
			petrol: 0.15301,
			hybrid: 0.10409,
			pluginhybrid: 0.02918 + 0.04034,
			electric: 0.04531,
		},
		medium: {
			diesel: 0.16877,
			petrol: 0.19158,
			hybrid: 0.10764,
			pluginhybrid: 0.0704 + 0.03579,
			electric: 0.05275,
		},
		large: {
			diesel: 0.20763,
			petrol: 0.28225,
			hybrid: 0.13022,
			pluginhybrid: 0.07685 + 0.04423,
			electric: 0.06635,
		},
	}
	var emission = distance * emissionFactors[carSize][fuelType] * week
	console.log(emission)
	sendEvent("Car", "Completed Calculation")
	return emission
}
export function calculateMotorEmission(motorType, distance) {
	var week = 52
	var emissionFactors = {
		small: 0.08277,
		medium: 0.10086,
		large: 0.13237,
	}
	var emission = distance * week * emissionFactors[motorType]
	console.log(emission)
	sendEvent("Motorbike", "Completed Calculation")
	return emission
}

export function calculatePcEmission(publicTransport, distance) {
	var week = 52
	var emissionFactors = {
		train: 0.03694,
		tram: 0.02991,
		bus: 0.10312,
	}
	var emission = distance * emissionFactors[publicTransport] * week
	console.log(emission)
	sendEvent("Public Transport", "Completed Calculation")
	return emission
}
export function calculateClothingEmission(clothing) {
	var months = 12
	var emissionFactors = {
		kgCO2PerPound: 0.632,
		kgCO2PerDollar: 0.48664,
	}
	var emission = clothing * emissionFactors.kgCO2PerDollar * months
	console.log(emission)
	sendEvent("Clothing", "Completed Calculation")
	return emission
}
export function calculateLeisureEmission(leisure) {
	var months = 12
	var emissionFactors = {
		kgCO2PerPound: 0.988,
		kgCO2PerDollar: 0.76076,
	}
	var emission = leisure * emissionFactors.kgCO2PerDollar * months
	console.log(emission)
	sendEvent("Leisure", "Completed Calculation")
	return emission
}
export function calculateDietEmissionSolo(diet) {
	var emissionFactorsDiet = {
		carnivore: 3300,
		omnivore: 2500,
		pescatarian: 1900,
		vegetarian: 1700,
		vegan: 1500,
	}
	var emission = emissionFactorsDiet[diet]
	return emission
}
export function calculateDietEmission(
	diet,
	waste,
	organicfood,
	localfood,
	recycle
) {
	const foodData = require("../../public/data/food-data.json") //with path

	var emissionFactorsDiet = {
		carnivore: 3300,
		omnivore: 2500,
		pescatarian: 1900,
		vegetarian: 1700,
		vegan: 1500,
	}

	var emissionFactorsWaste = {
		waste0005: 1.025,
		waste0510: 1.075,
		waste1020: 1.15,
		waste2030: 1.25,
		waste40: 1.4,
	}
	var recycleWaste = {
		yes: 181.9,
		no: 313.9,
	}
	var emissionDiet = emissionFactorsDiet[diet]
	var emissionLocalFood = foodData.local[localfood]
	var emissionOrganicFood = foodData.organic[organicfood]

	console.log(emissionDiet, emissionLocalFood, emissionOrganicFood)

	var emission =
		emissionDiet * emissionFactorsWaste[waste] +
		emissionLocalFood +
		emissionOrganicFood -
		recycleWaste[recycle]

	console.log(emission)
	sendEvent("Food", "Completed Calculation")
	return emission
}
export function getDistanceFromLatLonInKm(
	lat1,
	lon1,
	lat2,
	lon2,
	flightType,
	cabinClass
) {
	const flightsData = require("../../public/data/flights-data.json") //with path
	function deg2rad(deg) {
		return deg * (Math.PI / 180)
	}
	console.log(flightsData)
	var R = 6371 // Radius of the earth in km
	var dLat = deg2rad(lat2 - lat1) // deg2rad below
	var dLon = deg2rad(lon2 - lon1)
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) *
			Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2)

	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	var d = R * c // Distance in km
	console.log(d)
	if (d < 700) {
		var type = "domestic"
	} else if (d >= 700 && d <= 2500) {
		var type = "smallhaul"
	} else {
		var type = "longhaul"
	}
	console.log(type, cabinClass, flightType)
	console.log(flightsData[type][cabinClass])
	const emission = d * flightsData[type][cabinClass]
	var finalEmmision = emission * flightType
	sendEvent("Flights", "Completed Calculation")
	return [finalEmmision, d]
}
export function calculateGas(quantity, household) {
	const gasEmissionFactor = 1.88 // per m3
	const emission = (quantity * gasEmissionFactor) / household
	sendEvent("Gas", "Completed Calculation")
	return emission
}
export function calculateWood(quantity, household) {
	const woodEmissionFactor = 0.556 // per kg
	const emission = (quantity * woodEmissionFactor) / household
	sendEvent("Wood", "Completed Calculation")
	return emission
}
export function calculatePetroleum(quantity, household) {
	const petroleumEmissionFactor = 3.19
	const emission = (quantity * petroleumEmissionFactor) / household
	sendEvent("Petroleum", "Completed Calculation")
	return emission
}
export function calculateCoal(quantity, household) {
	const coalEmissionFactor = 3.26
	const emission = (quantity * coalEmissionFactor) / household
	sendEvent("Coal", "Completed Calculation")
	return emission
}
export function calculateFuels(fuelType, quantity, household) {
	const fuelData = require("../../public/data/stationary-fuels.json") //with path
	var ch4GWP = 21
	var n2oGWP = 310
	console.log(fuelType)

	let filteredArray = fuelData.filter(function (item) {
		console.log(item.type)
		console.log(fuelType)
		return item.type === fuelType
	})

	console.log(filteredArray)

	console.log(fuelType, quantity, household)
	//m3quantity = quantity / 35,315
	const amountx = quantity * (filteredArray[0].energyPerMass / 1000000)
	const amount = amountx / 35.215

	console.log(amountx)
	console.log(amount)

	// get CH4 emissions factor
	const massCH4PerEnergy = filteredArray[0].massCH4PerEnergy / 1000

	// get N2O emissions factor & convert g to kg
	const massN2OPerEnergy = filteredArray[0].massN2OPerEnergy / 1000

	// massCO2PerEnergy
	const co2Emissions = filteredArray[0].massCO2PerEnergy * amount
	const ch4Emissions = massCH4PerEnergy * amount
	const n2oEmissions = massN2OPerEnergy * amount
	const co2eEmissions =
		co2Emissions + ch4Emissions * ch4GWP + n2oEmissions * n2oGWP

	console.log(ch4Emissions, n2oEmissions)
	var emission = co2eEmissions / household
	console.log(emission)
	sendEvent("Fuels", "Completed Calculation")
	return emission
}
