import React, { useState, useEffect } from "react"
import "./styles/style.css"
import { Axios, db } from "./firebase/firebaseConfig"
import loadable from "@loadable/component"
import { Box } from "@material-ui/core/"
import CookieConsent from "react-cookie-consent"

const Footer = loadable(() => import("./components/Footer"))
const FooterContent = loadable(() => import("./components/FooterContent"))
const LoadingHome = loadable(() => import("./components/LoadingHome"))
const Routes = loadable(() => import("./utils/Routes"))
const AppBar = loadable(() => import("./components/AppBar"))
const ScrollToTop = loadable(() => import("./utils/ScrollToTop"))

import {
	calculateElectricityEmission,
	calculateElectricityGreen,
	calculateDietEmissionSolo,
	calculateCarEmission,
	calculatePcEmission,
	calculateMotorEmission,
	calculateClothingEmission,
	calculateLeisureEmission,
	calculateGas,
	getDistanceFromLatLonInKm,
	calculateDietEmission,
	calculatePetroleum,
	calculateWood,
	calculateCoal,
} from "./utils/utils"

function App() {
	const [fields, setFields] = useState({
		email: "",
		country: "",
	})

	// add inutlist
	const [inputList, setInputList] = useState({
		housing: [
			{
				name: "Housing",
				household: "",
				recycling: "",
				emission: "",
			},
		],
		electricity: [
			{
				name: "Electricity",
				average: "",
				household: "",
				electricity: "",
				green: "",
				emission: "",
			},
		],
		gas: [
			{
				name: "Gas",
				gas: "",
				emission: "",
			},
		],
		coal: [
			{
				name: "Gas",
				coal: "",
				emission: "",
			},
		],
		wood: [
			{
				name: "Wood",
				wood: "",
				emission: "",
			},
		],
		petroleum: [
			{
				name: "Petroleum",
				petroleum: "",
				emission: "",
			},
		],
		clothing: [
			{
				name: "Clothing",
				clothing: "",
				secondhand: "",
				emission: "",
			},
		],
		leisure: [
			{
				name: "Leisure",
				leisure: "",
				emission: "",
			},
		],
		consumption: [
			{
				name: "Consumption",
				leisure: null,
				clothing: null,
				secondhand: null,
				clothingEmission: null,
				leisureEmission: null,
				emission: "",
			},
		],
		food: [
			{
				name: "Food & Waste",
				diet: "",
				waste: "",
				organicfood: "",
				localfood: "",
				recycle: "",
				emission: "",
			},
		],
		car: [
			{
				name: "Car",
				carSize: "",
				fuelType: "",
				distance: "",
				emission: "",
			},
		],
		motorbike: [
			{
				name: "Motorbike",
				motorSize: "",
				distance: "",
				emission: "",
			},
		],
		publicTransport: [
			{
				name: "Public Transport",
				publicTransport: "",
				distance: "",
				emission: "",
			},
		],
		flight: [
			{
				fromAirport: {
					fromAirport: null,
					lat: null,
					lon: null,
				},
				toAirport: {
					toAirport: null,
					lat: null,
					lon: null,
				},
				name: "Flights",
				distance: "",
				flightType: "",
				cabinClass: "",
				emission: "",
			},
		],
	})

	// main handle change
	const handleInput = (e, index) => {
		const { name, value, category, lat, lon, average } = e.target
		const list = { ...inputList }
		console.log(
			"name:",
			name,
			"value:",
			value,
			"category",
			category,
			"index",
			index,
			e
		)
		if (category === "electricity" && average === 0) {
			list["electricity"][0]["average"] = 0
		}

		if (name === "fromAirport" || name === "toAirport") {
			list[category][index][name][name] = value
			list[category][index][name]["lat"] = lat
			list[category][index][name]["lon"] = lon
			setInputList(list)
		} else {
			list[category][index][name] = value
			setInputList(list)
		}

		if (
			category === "flight" &&
			list[category][index]["fromAirport"]["lat"] &&
			list[category][index]["toAirport"]["lat"] !== null &&
			list[category][index]["cabinClass"] !== null
		) {
			const finalEmissionFlights = getDistanceFromLatLonInKm(
				list[category][index]["fromAirport"]["lat"],
				list[category][index]["fromAirport"]["lon"],
				list[category][index]["toAirport"]["lat"],
				list[category][index]["toAirport"]["lon"],
				list[category][index]["flightType"],
				list[category][index]["cabinClass"]
			)
			list[category][index]["emission"] = finalEmissionFlights[0]
			list[category][index]["distance"] = finalEmissionFlights[1]
		}

		if (
			category === "car" &&
			list[category][index]["carSize"] !== "" &&
			list[category][index]["fuelType"] !== "" &&
			list[category][index]["distance"] !== ""
		) {
			var finalEmissionCar = calculateCarEmission(
				list[category][index]["carSize"],
				list[category][index]["fuelType"],
				list[category][index]["distance"]
			)
			list[category][index]["emission"] = finalEmissionCar
		} else if (
			category === "motorbike" &&
			list[category][index]["motorSize"] !== "" &&
			list[category][index]["distance"] !== ""
		) {
			var finalEmissionMotor = calculateMotorEmission(
				list[category][index]["motorSize"],
				list[category][index]["distance"]
			)
			list[category][index]["emission"] = finalEmissionMotor
		} else if (
			category === "publicTransport" &&
			list[category][index]["publicTransport"] !== "" &&
			list[category][index]["distance"] !== ""
		) {
			var finalEmissionPc = calculatePcEmission(
				list[category][index]["publicTransport"],
				list[category][index]["distance"]
			)
			list[category][index]["emission"] = finalEmissionPc
		} else if (
			category === "clothing" &&
			list[category][index]["clothing"] !== ""
		) {
			var finalEmissionClothing = calculateClothingEmission(
				list[category][index]["clothing"]
			)
			list[category][index]["emission"] = finalEmissionClothing
		} else if (
			category === "leisure" &&
			list[category][index]["leisure"] !== ""
		) {
			var finalEmissionLeisure = calculateLeisureEmission(
				list[category][index]["leisure"]
			)
			list[category][index]["emission"] = finalEmissionLeisure
		}
		// GAS
		else if (
			category === "gas" &&
			list[category][index]["gas"] !== "" &&
			list["electricity"][index]["household"] !== ""
		) {
			var finalEmissionGas = calculateGas(
				value,
				list["electricity"][index]["household"]
			)
			list[category][index]["emission"] = finalEmissionGas
		}
		// PETROL
		else if (
			category === "petroleum" &&
			list[category][index]["petroleum"] !== "" &&
			list["electricity"][index]["household"] !== ""
		) {
			var finalEmissionPetroleum = calculatePetroleum(
				value,
				list["electricity"][index]["household"]
			)
			list[category][index]["emission"] = finalEmissionPetroleum
		}
		//WOOD
		else if (
			category === "wood" &&
			list[category][index]["wood"] !== "" &&
			list["electricity"][index]["household"] !== ""
		) {
			var finalEmissionWood = calculateWood(
				value,
				list["electricity"][index]["household"]
			)
			list[category][index]["emission"] = finalEmissionWood
		}
		// COAL
		else if (
			category === "coal" &&
			list[category][index]["coal"] !== "" &&
			list["electricity"][index]["household"] !== ""
		) {
			var finalEmissionCoal = calculateCoal(
				value,
				list["electricity"][index]["household"]
			)
			list[category][index]["emission"] = finalEmissionCoal
		} else if (
			category === "electricity" &&
			list[category][index]["electricity"] !== "" &&
			list[category][index]["green"] !== "" &&
			list["electricity"][index]["household"] !== ""
		) {
			var finalEmissionElectricity = calculateElectricityEmission(
				list[category][index]["electricity"],
				fields.country,
				list["electricity"][index]["household"],
				list[category][index]["green"],
				list[category][index]["average"]
			)
			list[category][index]["emission"] = finalEmissionElectricity
		} else if (category === "food" && list[category][index]["diet"] !== "") {
			var finalEmissionDiet = calculateDietEmissionSolo(
				list[category][index]["diet"]
			)
			list[category][index]["emission"] = finalEmissionDiet
		} else if (
			category === "food" &&
			list[category][index]["diet"] !== "" &&
			list[category][index]["waste"] !== "" &&
			list[category][index]["organicfood"] !== "" &&
			list[category][index]["localfood"] !== "" &&
			list[category][index]["recycle"] !== ""
		) {
			var finalEmissionDiet = calculateDietEmission(
				list[category][index]["diet"],
				list[category][index]["waste"],
				list[category][index]["organicfood"],
				list[category][index]["localfood"],
				list[category][index]["recycle"]
			)
			list[category][index]["emission"] = finalEmissionDiet
		}
	}
	// handle click event of the Remove button
	const handleRemoveClick = (e, index) => {
		const { value } = e.currentTarget
		if (value === "removeFlight") {
			const list = { ...inputList }
			list.flight.splice(index, 1)
			setInputList(list)
		}
		if (value === "removePc") {
			const list = { ...inputList }
			list.publicTransport.splice(index, 1)
			setInputList(list)
		}
	}

	// handle click event of the Add button
	const handleAddClick = (e, index) => {
		const { value } = e.currentTarget
		if (value === "addFlight") {
			setInputList({
				...inputList,
				flight: [
					...inputList.flight,
					{
						fromAirport: {
							fromAirport: null,
							lat: null,
							lon: null,
						},
						toAirport: {
							toAirport: null,
							lat: null,
							lon: null,
						},
						name: "Flights",
						flightType: "",
						cabinClass: "",
						emission: "",
					},
				],
			})
		}
		if (value === "addPc") {
			setInputList({
				...inputList,
				publicTransport: [
					...inputList.publicTransport,

					{
						name: "Public Transport",
						publicTransport: "",
						distance: "",
						emission: "",
					},
				],
			})
		}
	}

	// handle submit and add to firebase
	const handleSubmit = (event) => {
		sendEmail()
	}

	const sendEmail = () => {
		Axios.post(
			"https://us-central1-plantamangrove.cloudfunctions.net/submit",
			inputList,
			fields
		)
			.then((res) => {
				db.collection("entries").add({
					country: fields.country,
					email: fields.email,
					inputlist: inputList,
					time: new Date(),
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const [spinner, setSpinner] = useState(true)
	useEffect(() => {
		setSpinner(false)
	}, [])

	// Handle fields change
	const handleChange = (e) => {
		const { value, name, input } = e.target
		setFields({
			...fields,
			[name]: value,
		})
	}
	return (
		<div style={{ overflow: "hidden" }}>
			<ScrollToTop />
			<AppBar />
			{spinner ? (
				<Box>
					<LoadingHome />
					<Footer timeout={0} position="footer fixed" />
				</Box>
			) : (
				<Box component="main">
					<Routes
						handleChange={handleChange}
						values={fields}
						handleInput={handleInput}
						handleRemoveClick={handleRemoveClick}
						handleAddClick={handleAddClick}
						inputList={inputList}
						handleSubmit={handleSubmit}
						setInputList={setInputList}
					/>
				</Box>
			)}

			{spinner ? (
				""
			) : (
				<Box>
					<Footer component="footer" timeout={0} position="footer absolute" />
					<FooterContent />
				</Box>
			)}
			<CookieConsent
				location="bottom"
				buttonText="I understand"
				cookieName="footprintforestcookie"
				style={{ background: "#18302A", fontSize: "12px" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
			>
				This website uses cookies to enhance the user experience.{" "}
				<span style={{ fontSize: "10px" }}>
					We track page analytics and carbon emission data.
				</span>
			</CookieConsent>
		</div>
	)
}

export default App
