import React from "react";
import { Box, Typography, Grid, CircularProgress } from "@material-ui/core/";

const LoadingHome = () => {
  return (
    <Box className="boxContainer home loading stretch trees">
      <Grid item xs={12} sm={12}>
        <Box>
          <Typography variant="h3" component="h1" align="center">
            <CircularProgress color="primary" />
            <br />
            Loading
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default LoadingHome;
